import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";

function App() {

  const [shares, setShares] = useState([]);
  const [dividends, setDividends] = useState([]);
  const [sharesData, setSharesData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [showDividends, setShowDividends] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [startDate, setStartDate] = useState(() => (new Date(Date.now() + ((5 * 60 + 30) * 60 * 1000))).toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(() => (new Date(Date.now() + (15 * 86400000) + ((5 * 60 + 30) * 60 * 1000))).toISOString().substring(0, 10));
  const [fromDays, setFromDays] = useState(0);
  const [toDays, setToDays] = useState(15);
  const [sortBy, setSortBy] = useState({ Column: "roi", desc: true });

  useEffect(() => {
    // refresh();
    setDividends([...(dividends.sort((a, b) => sortBy.Column === "name" ? sortBy.desc === true ? -1 : 1 :
      sortBy.Column === "divDate" ? sortBy.desc === true ? (new Date(b.divDate) - new Date(a.divDate)) : (new Date(a.divDate) - new Date(b.divDate)) :
        sortBy.Column === "announcementDate" ? sortBy.desc === true ? (new Date(b.announcementDate) - new Date(a.announcementDate)) : (new Date(a.announcementDate) - new Date(b.announcementDate)) :
          sortBy.Column === "dividend" ? sortBy.desc === true ? (parseFloat(b.dividend) - parseFloat(a.dividend)) : (parseFloat(a.dividend) - parseFloat(b.dividend)) :
            sortBy.Column === "lastValue" ? sortBy.desc === true ? (parseFloat(b.lastValue) - parseFloat(a.lastValue)) : (parseFloat(a.lastValue) - parseFloat(b.lastValue)) :
              sortBy.desc === true ? (parseFloat(b.roi) - parseFloat(a.roi)) : (parseFloat(a.roi) - parseFloat(b.roi))
    ))])
  }, [refreshData, sortBy]);
  useEffect(() => {
    const htm = window.open("https://www.nseindia.com", "_blank", "width=200,height=100");

    setTimeout(() => { htm.close(); getShares(); }, 3000)
  }, []);

  const refresh = () => {

    const apiUrl = "https://www.nseindia.com/api/corporates-corporateActions?index=equities&from_date=17-07-2021&to_date=1-08-2021";
    /*  var apiParam = {
       method: 'POST',
       headers: { startDate: (new Date((new Date(startDate)) - ((5 * 60 + 30) * 60 * 1000))).toISOString(), endDate: (new Date((new Date(endDate)) - ((-18 * 60 - 30) * 60 * 1000))).toISOString() }
     }; */
    fetch("https://www.nseindia.com", {
      credentials: 'include',
      accept: '*/*',
    })
      .then(res => alert(JSON.stringify(res)))
      .then(body => {
        alert(JSON.stringify(body))
        //setShares(body.data);
      })
      .catch(err => alert(JSON.stringify(err)));

  };
  const getShares = (e) => {
    var stDate = new Date(Date.now() + (parseInt(fromDays) * 86400000) + ((5 * 60 + 30) * 60 * 1000)).toISOString().substring(0, 10);
    var toDate = new Date(Date.now() + (parseInt(toDays) * 86400000) + ((5 * 60 + 30) * 60 * 1000)).toISOString().substring(0, 10);

    // const rp = require('request-promise');

    // //var j = rp.jar();
    // var cookie = rp.cookie("" + "mycookie");
    const url = "https://www.nseindia.com/api/corporates-corporateActions?index=equities&from_date=" + stDate.split(' - ')[0].split('-')[2] + '-' + stDate.split(' - ')[0].split('-')[1] + '-' + stDate.split(' - ')[0].split('-')[0] + "&to_date=" + toDate.split(' - ')[0].split('-')[2] + '-' + toDate.split(' - ')[0].split('-')[1] + '-' + toDate.split(' - ')[0].split('-')[0];
    //j.setCookie(cookie, url);
    // rp(url)
    //   .then(res => console.log(res))
    //   .then(body => {
    //     console.log(body)
    //     //setShares(body.data);
    //   })
    //   .catch(err => console.log(err));

    const htm = window.open(url, "_blank");
    setShares([])

    // setTimeout(() => {
    //   var htmBody = htm.document.getElementsByTagName("pre")[0].innerHTML;
    //   setShares(htmBody);
    // }, 3000)
    // setTimeout(() => {
    //   console.log(document.cookie.split(';'))
    // }, 3000)
    // fetch(url, {
    //   cookie: document.cookie
    // })
    //   .then(res => res.json())
    //   .then(body => {
    //     console.log(body)
    //     //setShares(body.data);
    //   })
    //   .catch(err => alert(JSON.stringify(err)));

  }
  const getDividends = async (e) => {
    const rp = require('request-promise');
    const url = 'https://api.moneycontrol.com/mcapi/v1/ecalendar/corporate-action?indexId=All&page=1&event=D&apiVersion=161&orderBy=asc&deviceType=W&duration=UP';

    await rp(url)
      .then(function (html) {
        var data = JSON.parse(html).data.list.map(company => { return { url: company.url, name: company.stockName, announcementDate: company.announcementDate, divDate: company.exDate, dividend: company.dividend, lastValue: company.lastValue, roi: parseFloat((parseFloat(company.dividend.replace(',', '')) * 100) / parseFloat(company.lastValue.replace(',', '')) || 0).toFixed(2) } })
        console.log(data)
        // data = data.sort((a, b) => sortBy.Column === "name" ? sortBy.desc === true ? -1 : 1 : sortBy.Column === "divDate" ? sortBy.desc === true ? (b.divDate - a.divDate) : (a.divDate - b.divDate) : sortBy.desc === true ? (b.announceDate - a.announceDate) : (a.announceDate - b.announceDate));
        setDividends([...data]);
        setShowDividends(true);
      })
      .catch(function (err) {
        //handle error
      });
  }

  // const getDividends_Old = (e) => {
  //   const rp = require('request-promise');
  //   const url = 'https://www.moneycontrol.com/stocks/marketinfo/dividends_declared/index.php';

  //   rp(url)
  //     .then(function (html) {
  //       //success!
  //       var htm = html.substring(html.lastIndexOf("<table"));
  //       htm = htm.substring(0, htm.indexOf("</table"));
  //       var cpny = htm.split('</tr>');
  //       var data = [];
  //       for (var i = 0; i < cpny.length; i++) {
  //         var name = cpny[i].substring(cpny[i].indexOf("<b>") + 3);
  //         name = name.substring(0, name.lastIndexOf("</b>"));
  //         var url = cpny[i].substring(cpny[i].indexOf("href=") + 6);
  //         url = "https://www.moneycontrol.com" + url.substring(0, url.indexOf("\""));
  //         var announce = cpny[i].substring(cpny[i].indexOf("</td>") + 4);
  //         announce = announce.substring(announce.indexOf("</td>") + 4);
  //         announce = announce.substring(announce.indexOf("</td>") + 4);
  //         announce = announce.substring(announce.indexOf(">") + 1);
  //         announce = announce.substring(announce.indexOf(">") + 1);
  //         announce = announce.substring(0, announce.indexOf("</td>"));
  //         var announceDateSplit = announce.split('-');
  //         var announceDate = new Date(announceDateSplit[2], announceDateSplit[1] - 1, parseInt(announceDateSplit[0]) + 1);
  //         var dividend = cpny[i].substring(0, cpny[i].lastIndexOf("</td>"));
  //         dividend = dividend.substring(dividend.lastIndexOf(">") + 1);
  //         var divDateSplit = dividend.split('-');
  //         var divDate = new Date(divDateSplit[2], divDateSplit[1] - 1, parseInt(divDateSplit[0]) + 1);

  //         if (name.length > 0 && divDate > (new Date()))
  //           data.push({ name, url, announceDate, divDate });
  //       }
  //       data = data.sort((a, b) => sortBy.Column === "name" ? sortBy.desc === true ? -1 : 1 : sortBy.Column === "divDate" ? sortBy.desc === true ? (b.divDate - a.divDate) : (a.divDate - b.divDate) : sortBy.desc === true ? (b.announceDate - a.announceDate) : (a.announceDate - b.announceDate));
  //       setDividends([...data]);
  //       setShowDividends(true);
  //     })
  //     .catch(function (err) {
  //       //handle error
  //     });
  // }

  const updateShares = (obj) => {
    var data = obj.filter(item => item.subject.toLowerCase().includes('dividend'));
    setSharesData([]);
    setHistoryData([]);
    setRefreshData(!refreshData);
    setShares(data);
    setShowDividends(false);
  }
  const updateSharesData = (idx, obj) => {
    var data = sharesData;
    if (obj.metadata.symbol !== shares[idx].symbol) {
      alert("Company Mismatch!")
      data[idx] = null;
    }
    else {
      data[idx] = obj;
    }
    setSharesData(data);
    setRefreshData(!refreshData);
  }

  const updateHistoryData = (idx, obj) => {
    var data = historyData;
    if (obj[0].symbol !== shares[idx].symbol) {
      alert("Company Mismatch!")
      data[idx] = null;
    }
    else {
      data[idx] = obj;
    }
    setHistoryData(data);
    setRefreshData(!refreshData);
  }


  const createHistory = (idx) => {
    var html = "<table style='border: 1px solid white; background-color: #282c34;min-height: 100vh;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;font-size: 16px;color: white;'><tr><td>Date</td><td>Dividend</td><td>Action</td></tr>";
    historyData[idx].map((row, key) => {
      html += "<tr><td>" + row.exDate + "</td><td>"
        + parseFloat(parseFloat(row.subject.split(' Per ').length > 1 ? row.subject.split(' Per ')[0].trim().substring(row.subject.split(' Per ')[0].trim().lastIndexOf(' ')) : "0")
          + parseFloat(row.subject.split(' Per ').length > 2 ? row.subject.split(' Per ')[1].trim().substring(row.subject.split(' Per ')[1].trim().lastIndexOf(' ')) : "0")
          + parseFloat(row.subject.split(' Per ').length > 3 ? row.subject.split(' Per ')[2].trim().substring(row.subject.split(' Per ')[2].trim().lastIndexOf(' ')) : "0")) + "</td><td>" + row.subject + "</td></tr>"
    });
    html += "</table>";
    return html;
  }

  const showHistory = (idx) => {
    var win = window.open("", "", "addressbar=no,toolbar=no,location=no,directories=no,status=no,menubar=no");
    win.document.body.innerHTML = createHistory(idx);
    win.document.title = shares[idx].comp;
  }
  const showResearch = (idx) => {
    var win = window.open("https://money.rediff.com/companies/" + shares[idx].comp.split('Limited')[0], "", "addressbar=no,toolbar=no,location=no,directories=no,status=no,menubar=no");
  }

  const calculateROI = (price, subject) => {
    return (((100 *
      (parseFloat(subject ? subject.split(' Per ').length > 1 ? subject.split(' Per ')[0].trim().substring(subject.split(' Per ')[0].trim().lastIndexOf(' ')) : "0" : "") +
        parseFloat(subject ? subject.split(' Per ').length > 2 ? subject.split(' Per ')[1].trim().substring(subject.split(' Per ')[1].trim().lastIndexOf(' ')) : "0" : "") +
        parseFloat(subject ? subject.split(' Per ').length > 3 ? subject.split(' Per ')[2].trim().substring(subject.split(' Per ')[2].trim().lastIndexOf(' ')) : "0" : "")))
      / parseFloat(price)).toFixed(2))
  }

  return (
    <div className="App">
      From Days:<input type='number' value={fromDays}
        onChange={(e) => {
          setFromDays(e.target.value);
          setStartDate(new Date(Date.now() + (parseInt(e.target.value) * 86400000) + ((5 * 60 + 30) * 60 * 1000)).toISOString().substring(0, 10));
          setRefreshData(!refreshData);
        }} />&nbsp;&nbsp;&nbsp;&nbsp;
      To Days:<input type='number' value={toDays} onChange={(e) => {
        setToDays(e.target.value);
        setEndDate(new Date(Date.now() + (parseInt(e.target.value) * 86400000) + ((5 * 60 + 30) * 60 * 1000)).toISOString().substring(0, 10));
        setRefreshData(!refreshData);
      }} />
      <a style={{ color: 'red' }} href={"https://www.nseindia.com/api/corporates-corporateActions?index=equities&from_date=" + startDate.split('-')[2] + '-' + startDate.split('-')[1] + '-' + startDate.split('-')[0] + "&to_date=" + endDate.split('-')[2] + '-' + endDate.split('-')[1] + '-' + endDate.split('-')[0]} target="_blank">Show Shares</a>
      <input value={JSON.stringify(shares)} onChange={(e) => updateShares(JSON.parse(e.target.value))}></input>
      <input value="Shares" type="button" onClick={getShares} />
      <input value="Dividend" type="button" onClick={() => getDividends()} />

      <header className="App-header">
        {showDividends ? <table>
          <tr>
            <td><button onClick={() => sortBy.Column === "name" ? setSortBy({ Column: "name", desc: !sortBy.desc }) : setSortBy({ Column: "name", desc: true })}>Company</button></td>
            <td><button onClick={() => sortBy.Column === "announcementDate" ? setSortBy({ Column: "announcementDate", desc: !sortBy.desc }) : setSortBy({ Column: "announcementDate", desc: true })}>Announced</button></td>
            <td><button onClick={() => sortBy.Column === "divDate" ? setSortBy({ Column: "divDate", desc: !sortBy.desc }) : setSortBy({ Column: "divDate", desc: true })}>Ex-Date</button></td>
            <td><button onClick={() => sortBy.Column === "dividend" ? setSortBy({ Column: "dividend", desc: !sortBy.desc }) : setSortBy({ Column: "dividend", desc: true })}>Dividend</button></td>
            <td><button onClick={() => sortBy.Column === "lastValue" ? setSortBy({ Column: "lastValue", desc: !sortBy.desc }) : setSortBy({ Column: "lastValue", desc: true })}>Latest Value</button></td>
            <td><button onClick={() => sortBy.Column === "roi" ? setSortBy({ Column: "roi", desc: !sortBy.desc }) : setSortBy({ Column: "roi", desc: true })}>ROI</button></td></tr>{
            dividends.map((comp) => <tr>
              <td><a href={comp.url} target='_blank'> {comp.name}</a></td>
              <td>{comp.announcementDate}</td>
              <td>{comp.divDate}</td>
              <td>{comp.dividend}</td>
              <td>{comp.lastValue}</td>
              <td style={{ backgroundColor: comp.roi >= 4 ? "red" : comp.roi >= 3 ? "orange" : "transparent", color: comp.roi >= 4 ? "white" : comp.roi >= 3 ? "black" : comp.roi >= 2 ? "red" : comp.roi >= 1 ? "orange" : "white" }}>{comp.roi}</td>
            </tr>)}
        </table> :
          <table>
            <tr>
              <td>Company</td>
              <td>Data</td>
              <td>Price</td>
              <td>Dividend</td>
              <td>ROI %</td>
              <td>Action</td>
              <td>History</td>
              <td>Industry</td>
              <td>Share P/E</td>
              <td>Sector P/E</td>
              <td>Face Value</td>
              <td>Date</td>
              <td>Listed On</td>
            </tr>
            {shares.map((row, key) => {
              return (<tr>
                <td><a style={{ color: 'white' }} href={"https://www.nseindia.com/api/quote-equity?symbol=" + row.symbol} target="_blank">{row.comp}</a><br />{(sharesData[key] || { priceInfo: { "weekHighLow": { min: "" } } }).priceInfo.weekHighLow.min}({(sharesData[key] || { priceInfo: { "weekHighLow": { minDate: "" } } }).priceInfo.weekHighLow.minDate})&nbsp;&nbsp;&nbsp;&nbsp;{(sharesData[key] || { priceInfo: { "weekHighLow": { max: "" } } }).priceInfo.weekHighLow.max}({(sharesData[key] || { priceInfo: { "weekHighLow": { maxDate: "" } } }).priceInfo.weekHighLow.maxDate})</td>
                <td><textarea value={sharesData[key] ? JSON.stringify(sharesData[key]) : ""} onChange={(e) => updateSharesData(key, JSON.parse(e.target.value))}></textarea></td>
                <td>{(sharesData[key] || { priceInfo: { "lastPrice": "" } }).priceInfo.lastPrice}</td>
                <td>{parseFloat(row.subject ? row.subject.split(' Per ').length > 1 ? row.subject.split(' Per ')[0].trim().substring(row.subject.split(' Per ')[0].trim().lastIndexOf(' ')) : "0" : "") + parseFloat(row.subject ? row.subject.split(' Per ').length > 2 ? row.subject.split(' Per ')[1].trim().substring(row.subject.split(' Per ')[1].trim().lastIndexOf(' ')) : "0" : "") + parseFloat(row.subject ? row.subject.split(' Per ').length > 3 ? row.subject.split(' Per ')[2].trim().substring(row.subject.split(' Per ')[2].trim().lastIndexOf(' ')) : "0" : "")}</td>
                <td style={{ color: sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 3 ? 'red' : sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 2 ? 'orange' : sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 1 ? 'yellow' : 'white', backgroundColor: sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 4 ? 'white' : "black", fontWeight: sharesData[key] && calculateROI(sharesData[key].priceInfo.lastPrice, row.subject) > 4 ? 'bolder' : "normal" }}>{(sharesData[key] || { priceInfo: { "lastPrice": "" } }).priceInfo.lastPrice === "" ? "" : calculateROI(sharesData[key].priceInfo.lastPrice, row.subject)}</td>
                <td><a style={{ color: 'white' }} href={"https://www.nseindia.com/api/corporates-corporateActions?index=equities&symbol=" + row.symbol} target="_blank">{row.subject}</a></td>
                <td><input type="text" value={historyData[key] ? JSON.stringify(historyData[key]) : ""} onChange={(e) => updateHistoryData(key, JSON.parse(e.target.value))} /><br /><input type="button" style={{ display: historyData[key] ? 'table-cell' : 'none' }} value="View" onClick={(e) => showHistory(key)} /><input style={{ display: 'table-cell' }} type="button" value="Research" onClick={(e) => showResearch(key)} /></td>
                <td>{(sharesData[key] || { metadata: { "industry": "" } }).metadata.industry}</td>
                <td style={{ color: sharesData[key] && parseFloat(sharesData[key].metadata.pdSymbolPe) <= 25 ? 'green' : '' }}>{(sharesData[key] || { metadata: { "pdSymbolPe": "" } }).metadata.pdSymbolPe}</td>
                <td>{(sharesData[key] || { metadata: { "pdSectorPe": "" } }).metadata.pdSectorPe}</td>
                <td>{row.faceVal}</td>
                <td>{row.exDate}</td>
                <td>{(sharesData[key] || { metadata: { "listingDate": "" } }).metadata.listingDate}</td>
              </tr>)
            })}
          </table>}
      </header>
    </div >
  );
}

export default App;
